<template>
  <component
    :is="require(`@/assets/vector/${icon}.svg`)"
    class="svg-icon"
    :class="{
      [`svg-fill-${color}`]: color,
      [`hover-icon-${hover}`]: hover
    }"
    v-bind="$attrs"
    v-on="$listeners"
    ref="comp"
  />
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    icon: String,
    color: {
      type: String,
      required: false
    },
    hover: {
      type: String,
      required: false
    }
  }
};
</script>
